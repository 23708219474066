import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { Link } from "gatsby";

import CalltoAction from "../components/CalltoAction";
import PictureGrid from "../components/PictureGrid";
import ColoredContent from "../components/ColoredContent";
import OverlappingContent from "../components/OverlappingContent";

const ImageArray = [
  { path: "../../window-cl-pr1.png", alt: "window-cl-pr1" },
  { path: "../../window-cl-pr2.png", alt: "window-cl-pr2" },
  { path: "../../window-cl-pr3.png", alt: "window-cl-pr3" },
  { path: "../../window-cl-pr4.png", alt: "window-cl-pr4" },
];

const ServicesTitleIconArray = [
  {
    serviceicon: "../../icon-window.png",
    alt: "window-cleaning",
    title: "Window Cleaning",
    href: "/services#window-cleaning",
  },
  {
    serviceicon: "../../icon-cleaning.png",
    alt: "rup",
    title: "Carpet Cleaning",
    href: "/services#carpet-cleaning",
  },
  {
    serviceicon: "../../icon-chair.png",
    alt: "ruchairp",
    title: "Contract Cleaning",
    href: "/services#contract-cleaning",
  },
  {
    serviceicon: "../../icon-sanitiser.png",
    alt: "SanitisingProducts",
    title: "Buy Products",
    href: "/contact",
  },
];

class About extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="google-site-verification"
            content="YAjmKXP8olHAA_JpbqTrbgHbFFvG4h8yvrpmb1lzL1w"
          />
          <title>
            About GG Cleaning Ireland | Window Cleaning, Carpet Cleaning,
            Contract Cleaning in Castlebar, Ireland
          </title>
          <description>
            GG Cleaning, based in Castlebar, County Mayo was established in 2010
            by Gareth & Charlotte Gargan. Our family run business focuses on
            high quality cleaning that exceeds industry standards.
          </description>
        </Helmet>

        <div>
          {/* Start header section */}
          <section className="header-section">
            <div className="d-flex align-items-center">
              <div className="col-lg-12 header-left order-lg-1 order-md-2 order-2 pad0">
                <div className="home-banner serviceBanner aboutBanner half-height-banner">
                  <div className="home-title">
                    <h2>About GG Cleaning</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End header section */}

          <ColoredContent
            sectionbg="#D91F3D"
            btnbgcolor="#292E34"
            btncolor="#fff"
            headingcolor="#fff"
            contentcolor="#fff"
            content="GG Cleaning, based in Castlebar, County Mayo was established in 2010 by Gareth & Charlotte Gargan.  Our family run business focuses on high quality cleaning that exceeds industry standards.  We believe our success is based on our dedication to high standards & professionalism.  Our staff are fully insured & professionally trained to fulfil all of your cleaning needs. "
          />

          {/* End about section */}

          {/* Start service section */}

          <OverlappingContent
            textbg="#292E34"
            titlecolor="#fff"
            overlapimg="../../middle-banner.png"
            arrowimg="../../arrow.png"
            ServicesTitleIcon={ServicesTitleIconArray}
          />

          <br />
          <br />
          <br />
          <br />
          <br />

          {/* Start ImageGallery section */}
          <div>
            <section className="section-gap project-area">
              <div className="projectTitle service-page-content">
                <div className="container">
                  <h4>Our Window Cleaning Projects</h4>
                  <a href="/projects" className="view-all-btn">
                    View All <img src="../../arrow.png" alt="arrow" />
                  </a>
                </div>
              </div>
              <div className="project-list">
                <PictureGrid Images={ImageArray} />
              </div>
            </section>
          </div>
          {/* End ImageGallery section */}

          {/* Start request section */}
          <CalltoAction
            bgimage="../../footer-baner.png"
            btnbgcolor="#D91F3D"
            btncolor="#fff"
            heading="Let us help your business shine"
            action="/contact"
            btnval="Request a quote"
          />
          {/* End request section */}
        </div>
      </Layout>
    );
  }
}

export default About;
