import React from "react";
export class ColoredContent extends React.Component {
    render() {
        return (
            <div>
                <section className="about-section section-gap" style={{ "backgroundColor": this.props.sectionbg }}>
                    <div className="container">
                        <div className="about-text wow slideInUp">
                            {this.props.heading && <h2 style={{ "color": this.props.headingcolor }}>{this.props.heading}</h2>}

                            {this.props.content && <p style={{ "color": this.props.contentcolor }}>{this.props.content}</p>}

                            {this.props.action && (
                                <div className="text-center">
                                    <a
                                        href={this.props.action}
                                        className="_btn _btn-black"
                                        style={{ "backgroundColor": this.props.btnbgcolor, "color": this.props.btncolor }}>
                                        {this.props.btnval}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default ColoredContent;
