import React from "react";
export class OverlappingContent extends React.Component {
    render() {
        const ServicesData = this.props.ServicesTitleIcon.map((Service, i) => {
            return (
                <li key={i}>
                    <a href={Service.href}>
                        <div className="service-icon">
                            <img src={Service.serviceicon} alt={Service.alt} />
                        </div>
                        <h4 style={{ paddingTop: "10px", color: this.props.titlecolor }}>{Service.title}</h4>
                        <img src={this.props.arrowimg} alt="arrow" />
                    </a>
                </li>
            );
        });

        return (
            <div>
                <section className="service-section section-gap" id="our-services">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-6 col-md-6 section-title-wrap pad0">
                                <img src={this.props.overlapimg} alt="middle-banner" />
                            </div>
                            <div className="col-lg-6 col-md-6 pad0">
                                <div className="service-content" style={{ "backgroundColor": this.props.textbg }}>
                                    <h3>Our Services</h3>
                                    <ul>{ServicesData}</ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default OverlappingContent;
